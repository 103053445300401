import React, { useEffect } from 'react';

const UTMHandler = () => {
    useEffect(() => {
        // Function to get UTM parameters from the URL
        const getUTMParams = () => {
            const params = new URLSearchParams(window.location.search);
            const utmParams = {
                utm_source: params.get('utm_source') || '',
                utm_medium: params.get('utm_medium') || '',
                utm_campaign: params.get('utm_campaign') || '',
                utm_term: params.get('utm_term') || '',
                utm_content: params.get('utm_content') || '',
            };
            return utmParams;
        };

        // Store the UTM parameters in localStorage
        const utmParams = getUTMParams();
        if (Object.values(utmParams).some((param) => param !== '')) {
            localStorage.setItem('utm_params', JSON.stringify(utmParams));
        } else {
            localStorage.removeItem('utm_params');
        }

    }, []);

    return null; // This component does not render anything
};

export default UTMHandler;
