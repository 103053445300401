const UTMData = (data) => {
    let utmParams = JSON.parse(localStorage.getItem('utm_params')) || {};

    // Add UTM parameters to form data
    data.utm_source = utmParams.utm_source || '';
    data.utm_medium = utmParams.utm_medium || '';
    data.utm_campaign = utmParams.utm_campaign || '';
    data.utm_term = utmParams.utm_term || '';
    data.utm_content = utmParams.utm_content || '';
    return data;
};

export default UTMData;
