import React, {useEffect, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import get from "lodash/get";
import omit from "lodash/omit";
import * as yup from "yup";
import FormField from "../../../components/forms/FormField";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormButton from "../../../components/forms/FormButton";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import FormAddressLookup from "../../../components/forms/FormAddressLookup";
import FormDateField from "../../../components/forms/FormDateField";
import UTMData from "../../../util/UTMData";

const AccountSchema = yup.object().shape({
    applicant_1_first_name: yup.string().required("First name of applicant one is required"),
    applicant_1_last_name: yup.string().required("Last name of applicant one is required"),
    applicant_1_email: yup.string().email("Must be a valid email address").required("Email address of applicant one is required"),
    applicant_1_dob: yup.string().required("Date of birth of applicant one is required"),

    // physical_address: yup.string().required("Physical address is required"),
    // postal_address: yup.string().required("Postal address is required"),
    phone: yup.string(),
    mobile: yup.string().required("Mobile phone is required"),

    additional_applicant: yup.boolean(),

    applicant_2_first_name: yup.string()
        .when("additional_applicant", (additional_applicant, schema) => additional_applicant ? schema.required("First name of applicant two is required") : schema),
    applicant_2_last_name: yup.string()
        .when("additional_applicant", (additional_applicant, schema) => additional_applicant ? schema.required("Last name of applicant two is required") : schema),
    applicant_2_email: yup.string().email("Must be a valid email address")
        .when("additional_applicant", (additional_applicant, schema) => additional_applicant ? schema.required("Email address of applicant two is required") : schema),
    applicant_2_dob: yup.string()
        .when("additional_applicant", (additional_applicant, schema) => additional_applicant ? schema.required("Date of birth of applicant two is required") : schema)
});

const Account = () => {
    const {register, handleSubmit, errors, setValue, watch, setError} = useForm({validationSchema: AccountSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();
    const [showApplicantTwo, setShowApplicantTwo] = useState(false);

    const onSubmit = async data => {
        const manipulate = (data) => {
            const updated = {...data};
            updated.applicant_1_name = `${updated.applicant_1_first_name} ${updated.applicant_1_last_name}`;

            if(!updated.physical_address) {
                const combinedPhysicalAddress = [updated.physical_street, updated.physical_suburb, updated.physical_city, updated.physical_postcode].filter(Boolean).join(', ');
                updated.physical_address = combinedPhysicalAddress;
            }
            if(!updated.postal_address) {
                const combinedPostalAddress = [updated.postal_street, updated.postal_suburb, updated.postal_city, updated.postal_postcode].filter(Boolean).join(', ');
                updated.postal_address = combinedPostalAddress;
            }

            if (updated.applicant_2_first_name) {
                updated.applicant_2_name = `${updated.applicant_2_first_name} ${updated.applicant_2_last_name}`;
            } else {
                updated.applicant_2_name = '';
            }
            if (!data.additional_applicant) {
                return omit(updated, [
                    "applicant_2_fuelcard_type",
                    "applicant_2_fuelcard_name",
                    "applicant_2_fuelcard_vehicle_rego",
                    "applicant_2_fuelcard_vehicle_description",
                    "applicant_2_fuelcard_pin",
                    "applicant_2_fuelcard_purchase_details",
                    "applicant_2_fuelcard_transaction_limit",
                    "applicant_2_fuelcard_bpme_app"
                ]);
            }
            return updated;
        };

        data = UTMData(data);
        submitFormStep({data, step: 1, url: "/bulk-individual/references", setError, manipulate});
    };

    useEffect(() => {
        if (!showApplicantTwo) {
            setValue("applicant_2_first_name", undefined);
            setValue("applicant_2_last_name", undefined);
            setValue("applicant_2_email", undefined);
            setValue("applicant_2_dob", undefined);
        }
    }, [showApplicantTwo, setValue]);

    useEffect(() => {
        runScrollToTop();
        updateFormStep(1);
        const values = loadSavedFormValues(setValue);
        if (get(values, "additional_applicant", false)) setShowApplicantTwo(true);
    }, [setValue, updateFormStep, loadSavedFormValues]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>

            <FormErrorsAlert errors={errors}/>

            <div className="section">
                <h2>Applicant One</h2>

                <Alert variant="warning">
                    Please note, on completion of this form, applicants will be sent an email inviting them to
                    sign the form electronically. When signing the form, all applicants will need to upload
                    a copy of their passport or driving license.
                </Alert>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="First Name"
                            name="applicant_1_first_name"
                            ref={register}
                            id="applicant_1_first_name"
                            type="text"
                            required={true}
                            error={errors.applicant_1_first_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField
                            label="Last Name"
                            name="applicant_1_last_name"
                            ref={register}
                            id="applicant_1_last_name"
                            type="text"
                            required={true}
                            error={errors.applicant_1_last_name}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="applicant_1_email" ref={register} id="applicant_1_email" type="email" required={true}
                                   error={errors.applicant_1_email}/>
                    </div>
                    <div className="col-md-6">
                        <FormDateField label="Date of Birth"
                                       name="applicant_1_dob"
                                       ref={register}
                                       id="applicant_1_dob"
                                       required={true}
                                       watch={watch}
                                       setValue={setValue}
                                       error={errors.applicant_1_dob}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Trading as (if applicable)" name="trading_as" ref={register} id="trading_as" type="text" error={errors.trading_as}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Form.Group controlId="additional_applicant">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="additional_applicant"
                                ref={register}
                                label="Are you including an additional applicant?"
                                onClick={(e) => {
                                    const checked = e.target.checked;
                                    setShowApplicantTwo(checked);
                                }} />
                        </Form.Group>
                    </div>
                </div>
            </div>


            <div className={`section ${!showApplicantTwo ? "d-none" : ""}`}>
                <h2>Applicant Two</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="First Name"
                            name="applicant_2_first_name"
                            ref={register}
                            id="applicant_2_first_name"
                            type="text"
                            required={true}
                            error={errors.applicant_2_first_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField
                            label="Last Name"
                            name="applicant_2_last_name"
                            ref={register}
                            id="applicant_2_last_name"
                            type="text"
                            required={true}
                            error={errors.applicant_2_last_name}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="applicant_2_email" ref={register} id="applicant_2_email" type="email" required={true}
                                   error={errors.applicant_2_email}/>
                    </div>
                    <div className="col-md-6">
                        <FormDateField label="Date of Birth"
                                       name="applicant_2_dob"
                                       ref={register}
                                       id="applicant_2_dob"
                                       required={true}
                                       watch={watch}
                                       setValue={setValue}
                                       error={errors.applicant_2_dob}/>
                    </div>
                </div>
            </div>


            <div className="section">
                <h2>Contact Details</h2>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Physical Address"
                            name="physical_address"
                            register={register}
                            id="physical_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors} 
                            applicantOrDirector={true}
                            extraFieldsName={"physical"}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="postal_address"
                            register={register}
                            id="postal_address"
                            required={true}
                            setValue={setValue}
                            watch={watch}
                            error={errors} 
                            applicantOrDirector={true}
                            extraFieldsName={"postal"}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Mobile Number" name="mobile" ref={register} id="mobile" type="text" required={true} error={errors.mobile}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Phone Number" name="phone" ref={register} id="phone" type="text" required={false} error={errors.phone}/>
                    </div>
                </div>
            </div>


            <div className="section">
                <h2>Preferences</h2>

                <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="email_invoices">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="email_invoices"
                                ref={register}
                                label="Send invoices by email?"/>
                        </Form.Group>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="email_delivery_docket">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="email_delivery_docket"
                                ref={register}
                                label="Send delivery docket by email?"/>
                        </Form.Group>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="sms_delivery_docket">
                            <Form.Check
                                custom
                                type="checkbox"
                                name="sms_delivery_docket"
                                ref={register}
                                label="Send delivery docket by text?"/>
                        </Form.Group>
                    </div>
                </div>*/}
            </div>


            <div className="section">
                <h2>Accountant Details</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Full Name" name="accountant_name" ref={register} id="accountant_name" type="text" error={errors.accountant_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField label="Business Name" name="accountant_business_name" ref={register} id="accountant_business_name" type="text"
                                   error={errors.accountant_business_name}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <FormAddressLookup
                            label="Postal Address"
                            name="accountant_postal_address"
                            register={register}
                            id="accountant_postal_address"
                            required={false}
                            setValue={setValue}
                            watch={watch}
                            error={!!errors.accountant_postal_address} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Phone Number" name="accountant_phone" ref={register} id="accountant_phone" type="text"
                                   error={errors.accountant_phone}/>
                    </div>
                </div>
            </div>


            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/bulk-individual" variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Account;
