import React, {useEffect} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import FormField from "../../../components/forms/FormField";
import {Link} from "react-router-dom";
import {useFormManager} from "../../../util/form-manager";
import FormButton from "../../../components/forms/FormButton";
import {runScrollToTop} from "../../../util/scroll-to-top";
import FormErrorsAlert from "../../../components/forms/FormErrorsAlert";
import UTMData from "../../../util/UTMData";

const AccountSchema = yup.object().shape({
    account_customer_code: yup.string().required("Customer code / name is required"),
    account_first_name: yup.string().required("First name is required"),
    account_last_name: yup.string().required("Last name is required"),
    account_email: yup.string().email("Must be a valid email address").required("Email address is required")
});

const Account = () => {
    const {register, handleSubmit, errors, setValue, setError} = useForm({validationSchema: AccountSchema});
    const {updateFormStep, loadSavedFormValues, submitFormStep, submitFormStepLoading} = useFormManager();

    const onSubmit = async data => {
        data = UTMData(data);
        submitFormStep({data, step: 1, url: "/new-fuelcard/fuelcard", setError});
    };

    useEffect(() => {
        runScrollToTop();
        updateFormStep(1);
        loadSavedFormValues(setValue);
    }, [setValue, updateFormStep, loadSavedFormValues]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>

            <FormErrorsAlert errors={errors}/>

            <div className="section">
                <h2>Account Details</h2>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="Customer Code"
                            name="account_customer_code"
                            ref={register}
                            id="account_customer_code"
                            type="text"
                            required={true}
                            error={errors.account_customer_code}
                            enforceOneWord={true}
                            placeholder="C00674"
                        />
                    </div>
                </div>

                <Alert variant="warning">
                    A director or authorised person on the account must provide these details and will be required to
                    sign the form.
                </Alert>

                <div className="row">
                    <div className="col-md-6">
                        <FormField
                            label="First Name"
                            name="account_first_name"
                            ref={register}
                            id="account_first_name"
                            type="text"
                            required={true}
                            error={errors.account_first_name}/>
                    </div>
                    <div className="col-md-6">
                        <FormField
                            label="Last Name"
                            name="account_last_name"
                            ref={register}
                            id="account_last_name"
                            type="text"
                            required={true}
                            error={errors.account_last_name}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField label="Email Address" name="account_email" ref={register} id="account_email"
                                   type="email" required={true}
                                   error={errors.account_email}/>
                    </div>
                </div>

            </div>


            <div className="section">
                <div className="row">
                    <div className="col-6 d-flex align-items-center justify-content-start">
                        <Button disabled={submitFormStepLoading} as={Link} to="/new-fuelcard"
                                variant="light">Back</Button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-end">
                        <FormButton loading={submitFormStepLoading} variant="primary" type="submit">Next</FormButton>
                    </div>
                </div>
            </div>

        </Form>
    );
};

export default Account;
